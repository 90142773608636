// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Hero from './Hero';
import LogoList from './LogoList';
import Meta from '../shared/Meta';
import CostEstimator from './CostEstimator';
import Triple from './Triple';
import VendorsToChooseFrom from './VendorsToChooseFrom';
import AnyEvent from './AnyEvent';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import { getUrl } from '../../modules/helpers';
import styles from '../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from './Triple.module.postcss';

class Catering extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    const isPortland = (cityConfig.city_name === "Portland");
    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div className="CateringPage">
        <Meta
          title={`Food ${cityConfig.vehicle_type} Catering | ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`Book ${cityConfig.city_name}’s best food ${vehicleType}s for your next event. Use our free no-obligation request form to book one or multiple ${vehicleType}s for that special day!`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Catering",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Hero photo="https://static.seattlefoodtruck.com/photos/cateringhero.jpg">
          <h1>Food {cityConfig.vehicle_type} Catering</h1>
          <p>Book {cityConfig.city_name}'s best food {vehicleType}s for your next event. Use our <b>free no-obligation request form</b> to book one or multiple {vehicleType}s for that special day!</p>
          <a href="/catering/register" className="Button">
            Get started
          </a>
        </Hero>

        <section className="PaddedSection">
          <div className="Container">
            <h3>How it works</h3>
            <div className={tripleStyles.Triple_wrapper}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering1.png"
                title="1. Answer a few questions"
                text={`Tell us more about your upcoming event and food ${vehicleType} needs.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering2.png"
                title="2. Receive quotes"
                text={`Get quotes from available food ${vehicleType}s based on your event and budget.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering3.png"
                title="3. Book your truck"
                text={`Compare quotes, message food ${vehicleType}s directly and book the ones you like best.`}
              />
            </div>
          </div>
        </section>

        <VendorsToChooseFrom />

        <CostEstimator />

        <section className="PaddedSection">
          <div className="Container">
            <LogoList portland={isPortland}/>
          </div>
        </section>

        <AnyEvent/>

        <section className="PaddedSection">
          <div className="Container">
            <div className={styles.BookingCta}>
              <h3>Think of us for your next event</h3>
              <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {vehicleType}s.</p>
              <a
                className="Button"
                href="/catering/register"
              >
                Food {cityConfig.vehicle_type} Catering
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Catering.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Catering);
